export default {
  testId: -1,
  productId: -1,
  utid: -1,
  curTestNum: -1,
  title: "",
  reportId: 0,
  curType: 0, //首页分类
  userType: 0, //我的测评分类
  showMenuHolder: false, //显示底下的holder站位
  testCount: {
    //倒计时
    hour: 0,
    minute: 0,
    second: 0,
  }, //
  times: 0,
};
