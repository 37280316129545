<template>
	<router-view>
	</router-view>
	<!-- <Menu /> -->
</template>

<script>
// import { reactive, onMounted, ref, toRefs } from 'vue';
// import { login } from './utils';
import Menu from "@/views/Menus"
import VConsole from 'vconsole';
import { queryString } from './utils';

//alert('3');
export default {
	name: 'App',
	components: {
		Menu
	},
	setup() {
		if(queryString('debug')){
			const vConsole = new VConsole();
		}
	}
}
</script>

<style>
@import "./assets/css/rest.css";
@import "./assets/css/css.css";

html,
body {
	width: 100%;
	height: 100%;
}

#app {
	background-color: white; 
	width: 100%;
	height: 100%;
	overflow-x: hidden;
	overflow-y: visible;
}

.answer .tips .red {
	color: blue !important;
	background-position-x: 0 !important;
	background-position-y: 2px !important;
	background-size: 36px 36px !important;
}

.answer .over .txtA {
	background-size: 68px 64px !important;
}
</style>
